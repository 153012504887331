import { Fragment } from 'react';

import {
  Box,
  Center,
  Divider,
  Flex,
  HStack,
  Link,
  Show,
  Text,
} from '@chakra-ui/react';

import Icon from '@/components/Icon';
import { LP_SOCIAL, LP_TOS_CLICK } from '@/constants/analytics';
import { trackEvent } from '@/dapp-sdk-v2';
import BaseImage from '@/dapp-sdk-v2/components/Image';
import { IconBackgroundContainer } from '@/themes';
import { getCommunityIcon } from '@/utils/globalCommon';

// Components
import Column from './components/Column';
import { iconMapping } from './constants';
// Constants
import { HeaderDataType } from './types';

type FooterProps = {
  data: HeaderDataType;
};

const Footer = ({ data }: FooterProps) => {
  const { navItems, socials, copyrightText, icon_key, brand, footerLinks } =
    data;

  return (
    <Center bg="#142528" w="full" color="white">
      <Box
        w="full"
        maxW="1224px"
        borderRadius={2}
        textAlign="left"
        pt="80px"
        px={{ base: 2, lg: 4 }}
        pb={3}
      >
        <Flex
          w="full"
          justify="space-between"
          flexDir={{ base: 'column', lg: 'row' }}
          px={{ base: 4, lg: 0 }}
        >
          <Box>
            <Flex align="center" ml={-2} as="a" href="/">
              <Show above="lg">
                <BaseImage
                  src={iconMapping[icon_key]}
                  alt="stader-icon"
                  width={40}
                  height={40}
                />
              </Show>
              <Show below="lg">
                <BaseImage
                  src={iconMapping[icon_key]}
                  alt="stader-icon"
                  width={32}
                  height={32}
                />
              </Show>
              <Text fontSize={{ base: 22, lg: 28 }} fontWeight={600}>
                {brand}
              </Text>
            </Flex>
            <HStack mt={6} align="center" gap={2}>
              {socials.map(({ imgKey, link }) => (
                <Link
                  key={imgKey}
                  href={link}
                  isExternal
                  onClick={() =>
                    trackEvent(LP_SOCIAL, {
                      social_cta: 'footer',
                      social_name: imgKey,
                    })
                  }
                >
                  <IconBackgroundContainer
                    bg="#E6F8EF"
                    height={{ base: '32px', lg: '40px' }}
                    width={{ base: '32px', lg: '40px' }}
                  >
                    <Box
                      height={{ base: '20px', lg: '24px' }}
                      width={{ base: '20px', lg: '24px' }}
                    >
                      <Icon
                        Icon={getCommunityIcon(imgKey)}
                        height="100%"
                        width="100%"
                      />
                    </Box>
                  </IconBackgroundContainer>
                </Link>
              ))}
            </HStack>
          </Box>
          <Flex
            mt={{ base: 10, lg: 0 }}
            flexDir={{ base: 'column', lg: 'row' }}
            gap={10}
            flexWrap="wrap"
            w={{ base: 'auto', lg: 812 }}
          >
            {navItems.map(({ title, details }) => (
              <Column key={`footer-${title}`} label={title} items={details} />
            ))}
          </Flex>
        </Flex>
        <Flex
          justify="space-between"
          flexDir={{ base: 'column', lg: 'row' }}
          align={{ base: 'flex-start', lg: 'center' }}
          py={8}
          px={{ base: 4, lg: 0 }}
          fontSize={{ base: 14, lg: 16 }}
          gap={4}
          opacity={0.7}
        >
          <Text>{copyrightText}</Text>

          <Flex gap="0.5rem" alignItems="center">
            {footerLinks.links.map((link, index) => (
              <Fragment key={link.link}>
                <Link
                  key={link.key}
                  href={link.link}
                  isExternal={true}
                  onClick={() => {
                    trackEvent(LP_TOS_CLICK, {
                      cta: link.title,
                      link: link,
                    });
                  }}
                >
                  {link.title}
                </Link>
                {index !== footerLinks.links.length - 1 && (
                  <Divider
                    orientation="vertical"
                    borderWidth="1px"
                    height={{ base: '12px', lg: '14px' }}
                  />
                )}
              </Fragment>
            ))}
          </Flex>
        </Flex>
      </Box>
    </Center>
  );
};

export default Footer;
