import { Box, HStack, Tag, Text } from '@chakra-ui/react';

import { EVENT_MAPPINGS } from '@/constants/analytics';
import { trackEvent } from '@/dapp-sdk-v2';

type Props = {
  label: string;
  items: Array<{ label: string; detailId: string; link: string; tag?: string }>;
};

const Column = ({ label: title, items }: Props) => {
  return (
    <Box textAlign="left" w={244}>
      <Text
        fontSize={{ base: 20, lg: 22.5 }}
        fontWeight={{ base: 600, lg: 700 }}
        mb={{ base: 3, lg: 4 }}
      >
        {title}
      </Text>
      {items.map(({ detailId, label, link, tag }) => (
        <HStack key={detailId} align="center" gap={3} opacity={0.7} mb={3}>
          <Text
            fontSize={{ base: 12, lg: 18 }}
            lineHeight={{ base: '15px', lg: '21px' }}
            as="a"
            display="block"
            href={link}
            target="_blank"
            rel={title === 'Networks' ? 'canonical' : 'noopener noreferrer'}
            key={detailId}
            onClick={() => {
              trackEvent(EVENT_MAPPINGS[title.toLowerCase()] || title, {
                cta: 'footer',
                name: label,
              });
            }}
          >
            {label}
          </Text>
          {tag && (
            <Tag
              className="tag"
              bg="#2196F3"
              py={1}
              px={2}
              textTransform="uppercase"
              color="white"
            >
              <Text fontSize={{ base: 10, lg: 12 }} fontWeight="700">
                {tag}
              </Text>
            </Tag>
          )}
        </HStack>
      ))}
    </Box>
  );
};

export default Column;
